import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { withPrefix } from "gatsby-link"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.pt-br"

const BlogPostTemplate = ({ data, location }) => {
  // console.log(data)
  const manufacturer = data.webiny.getManufacturer.data.manufacturer
  console.log("manufacturer", manufacturer)
  // console.log("machines", data.allFile.edges)

  const getMachineImage = slug => {
    let machineImage = data.allFile.edges.filter(
      element => element.node.name === slug
    )
    if (machineImage.length > 0) {
      return machineImage[0]["node"]
    } else {
      machineImage = data.allFile.edges.filter(
        element => element.node.name === "placeholder"
      )
      return machineImage[0]["node"]
    }
  }

  const listMachiens = () => {
    return (
      <div className="container mx-auto max-w-5xl">
        <div className="grid grid-cols-1 md:grid-cols-2 m-4 gap-8 auto-cols-max">
          {data.webiny.listMachines.data.map((machine, k) => {
            return (
              <div className="container mx-auto" key={k}>
                <div className="border border-mi-gray">
                  <Link
                    to={withPrefix(
                      `/${machine["language"]}/${machine["slug"]}`
                    )}
                    key={k}
                  >
                    <GatsbyImage
                      image={getImage(getMachineImage(machine["slug"]))}
                      backgroundColor={false}
                      alt={machine["menuTitle"]}
                    />
                    <div className="bg-mi-gray text-center">
                      <div className="text-mi-blue text-center text-3xl font-bold pt-6">
                        <div style={{ height: "78px" }} className="mx-4">
                          {machine["menuTitle"]}
                        </div>
                      </div>
                      <div className="text-mi-blue text-center text-xl py-4">
                        <div style={{ height: "56px" }} className="mx-4">
                          {machine["title"]}
                        </div>
                      </div>
                      <div className="pt-6 pb-6">
                        <button className="text-mi-yellow text-lg bg-mi-blue py-3 px-6 rounded-full hover:bg-mi-light-blue">
                          mais informações
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <>
      <Metatags
        title={`Multilógica | ${manufacturer}`}
        description="Fornecedor de maquinário para colchões"
        url={`https://newsite.multilogica.ind.br/pt-br/${manufacturer.toLowerCase()}`}
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchão, máquina de costura, mammut, beckmann sew"
        language="pt-br"
      />
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        <GatsbyImage
          image={getImage(data[`banner${manufacturer}`])}
          backgroundColor={false}
          alt={manufacturer}
        />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="max-w-3xl mx-auto text-center">
            <div className="h-2" />
            {manufacturer === "Mammut" ? (
              <div className="text-white text-xl mx-4">
                Cada máquina Mammut é feita sob medida para atender às
                suas necessidades. Apesar disso, todos os tipos de máquinas têm
                claramente suas características de qualidade, que são adequadas
                para diferentes tarefas. Cada uma dessas máquinas provará ser
                uma máquina de costura bem construída e com desempenho
                excepcional.
              </div>
            ) : (
              <div className="text-white text-xl mx-4">
                Desde 1990 a Beckmann produz máquinas automáticas para produção
                de colchões e cintas. Mais de 30 anos de know-how, disposição
                máxima, qualidade intransigente, adaptado de forma flexível a
                cada solicitação individual do cliente.
              </div>
            )}
          </div>
          <div className="h-20" />
        </div>
      </div>
      <div className="container mx-auto text-center">
        <div>
          <GatsbyImage
            image={getImage(data[`logo${manufacturer}`])}
            backgroundColor={false}
            alt={manufacturer}
            className="m-12"
          />
        </div>
      </div>
      {listMachiens()}
      <div className="container mx-auto max-w-5xl">
        <div className="grid grid-cols-1 md:grid-cols-2 m-4 gap-8 auto-cols-max">
          <div />
          <div className="col-span-1 place-self-end">
            <GatsbyImage
              image={getImage(data.madeInGermany)}
              backgroundColor={false}
              alt="Made in Germany"
            />
          </div>
        </div>
      </div>
      <div className="h-20" />
      <Footer />
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query machinesAndManufacturersPT(
    $slug: String!
    $entryId: String!
    $language: String!
  ) {
    webiny {
      getManufacturer(where: { manufacturer: $slug }) {
        data {
          manufacturer
          entryId
        }
      }
    }
    webiny {
      listMachines(
        limit: 30
        sort: listPosition_ASC
        where: { manufacturer: { entryId: $entryId }, language: $language }
      ) {
        data {
          menuTitle
          title
          language
          manufacturer {
            manufacturer
          }
          videoIds
          listPosition
          description
          slug
          id
        }
      }
    }

    allFile(filter: { relativePath: { regex: "/menu-mammut/" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 540)
          }
          name
        }
      }
    }

    bannerMammut: file(relativePath: { eq: "banners/Banner-Menu-Mammut.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }

    bannerBeckmann: file(
      relativePath: { eq: "banners/Banner-Menu-Beckmann.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }

    logoMammut: file(relativePath: { eq: "logos/mammut.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 292)
      }
    }
    logoBeckmann: file(relativePath: { eq: "logos/beckmann.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 212)
      }
    }
    madeInGermany: file(relativePath: { eq: "logos/made-in-germany.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 120)
      }
    }
  }
`
